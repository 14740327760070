<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  created () {
    (function () { // 阻止微信设置字体大小影响公众号的布局
      if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
        handleFontSize()
      } else {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', handleFontSize)
          document.attachEvent('onWeixinJSBridgeReady', handleFontSize)
        }
      }
      function handleFontSize () {
        WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
        WeixinJSBridge.on('menu:setfont', function () {
          WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
        })
      }
    })()
  }
}
</script>
<style lang="scss">
@import './assets/css/base.css';
body{
  -webkit-text-size-adjust: 100% ; // 阻止微信设置字体大小影响公众号的布局
}
</style>
