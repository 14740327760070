import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决路由跳转多次点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  // 首页
  {
    path: '/Home',
    name: '首页',
    component: () => import('../views/home/Home.vue'),
    meta: {
      title: '首页',
      keepAlive: false
    }
  },
  {
    path: '/category',
    name: '选择分类',
    component: () => import('../views/home/Category.vue'),
    meta: {
      title: '选择分类',
      keepAlive: false
    }
  },
  {
    path: '/Policydetails',
    name: '政策',
    component: () => import('../views/home/Policydetails.vue'),
    meta: {
      title: '政策',
      keepAlive: false
    }
  },
  {
    path: '/Placeanorder',
    name: '预约下单',
    component: () => import('../views/home/Placeanorder.vue'),
    meta: {
      title: '选择分类',
      keepAlive: true
    }
  },

  // 我的
  {
    path: '/Profileuser',
    name: '我的',
    component: () => import('../views/my/Profileuser.vue'),
    meta: {
      title: '我的',
      keepAlive: false
    }
  },
  {
    path: '/detailed',
    name: '充值币明细',
    component: () => import('../views/my/detailed.vue'),
    meta: {
      title: '充值币明细',
      keepAlive: false
    }
  },
  {
    path: '/Myorder',
    name: '我的订单',
    component: () => import('../views/my/Myorder.vue'),
    meta: {
      title: '我的订单',
      keepAlive: true
    }
  },
  {
    path: '/Mydetails',
    name: '订单详情',
    component: () => import('../views/my/myorder/Mydetails.vue'),
    meta: {
      title: '订单详情',
      keepAlive: false
    }
  },
  {
    path: '/Storagepoint',
    name: '贮存点',
    component: () => import('../views/my/Storagepoint.vue'),
    meta: {
      title: '贮存点',
      keepAlive: false
    }
  },
  {
    path: '/Addstorage',
    name: '添加贮存点',
    component: () => import('../views/my/Addstorage.vue'),
    meta: {
      title: '添加贮存点',
      keepAlive: false
    }
  },
  {
    path: '/Environmentalcode',
    name: '固废码',
    component: () => import('../views/my/Environmentalcode.vue'),
    meta: {
      title: '固废码',
      keepAlive: false
    }
  },
  {
    path: '/Protectiondetails',
    name: '企业固废码信息',
    component: () => import('../views/my/Protectiondetails.vue'),
    meta: {
      title: '企业固废码信息',
      keepAlive: false
    }
  },

  // 清运员user
  {
    path: '/Personal',
    name: '我的',
    component: () => import('../views/user/Personal.vue'),
    meta: {
      title: '我的',
      keepAlive: false
    }
  },
  {
    path: '/Userorder',
    name: '我的订单',
    component: () => import('../views/user/Userorder.vue'),
    meta: {
      title: '我的订单',
      keepAlive: false
    }
  },
  {
    path: '/Destinationlist',
    name: '我的去向单',
    component: () => import('../views/user/Destinationlist.vue'),
    meta: {
      title: '我的去向单',
      keepAlive: false
    }
  },
  {
    path: '/Destinationlistdetails',
    name: '查询去向单',
    component: () => import('../views/user/Destinationlistdetails.vue'),
    meta: {
      title: '查询去向单',
      keepAlive: false
    }
  },
  {
    path: '/Adddestinationlist',
    name: '添加去向单',
    component: () => import('../views/user/Adddestinationlist.vue'),
    meta: {
      title: '添加去向单',
      keepAlive: false
    }
  },
  {
    path: '/Orderdetails',
    name: '订单详情',
    component: () => import('../views/user/userorder/details/Orderdetails.vue'),
    meta: {
      title: '订单详情',
      keepAlive: false
    }
  },
  {
    path: '/Editorder',
    name: '订单详情',
    component: () => import('../views/user/userorder/details/Editorder.vue'),
    meta: {
      title: '订单详情',
      keepAlive: false
    }
  },

  {
    path: '/Basic',
    name: '企业基本资料',
    component: () => import('../views/my/Basic.vue'),
    meta: {
      title: '企业基本资料',
      keepAlive: false
    }
  },
  {
    path: '/Details',
    name: '企业详细资料',
    component: () => import('../views/my/Details.vue'),
    meta: {
      title: '企业详细资料',
      keepAlive: false
    }
  },

  // 公共组件
  {
    path: '/Addresslist',
    name: '回收地址',
    component: () => import('../components/assembly/Addresslist.vue'),
    meta: {
      title: '回收地址',
      keepAlive: false
    }
  },
  {
    path: '/Addaddress',
    name: '添加地址',
    component: () => import('../components/assembly/Addaddress.vue'),
    meta: {
      title: '添加地址',
      keepAlive: false
    }
  },

  // 员工绑定
  {
    path: '/Binding',
    name: '员工绑定',
    component: () => import('../views/employee/Binding.vue'),
    meta: {
      title: '员工绑定',
      keepAlive: false
    }
  },
  {
    path: '/Applicant',
    name: '二维码',
    component: () => import('../views/employee/Applicant.vue'),
    meta: {
      title: '二维码',
      keepAlive: false
    }
  },
  {
    path: '/Scancodeapply',
    name: '扫码申请',
    component: () => import('../views/employee/Scancodeapply.vue'),
    meta: {
      title: '扫码申请',
      keepAlive: false
    }
  },

  // 企业管理员
  {
    path: '/Enterpriselist',
    name: '企业管理列表',
    component: () => import('../views/administration/Enterpriselist.vue'),
    meta: {
      title: '企业管理列表',
      keepAlive: true
    }
  },
  {
    path: '/Virtualcurrency',
    name: '给企业充值',
    component: () => import('../views/administration/Virtualcurrency.vue'),
    meta: {
      title: '给企业充值',
      keepAlive: false
    }
  },
  {
    path: '/Visitrecord',
    name: '走访记录',
    component: () => import('../views/administration/Visitrecord.vue'),
    meta: {
      title: '走访记录',
      keepAlive: false
    }
  },
  {
    path: '/Visitrecords',
    name: '走访记录',
    component: () => import('../views/administration/Visitrecords.vue'),
    meta: {
      title: '走访记录',
      keepAlive: false
    }
  },

  {
    path: '/Clearanceapplication',
    name: '清运员申请',
    component: () => import('../views/Clearanceapplication.vue'),
    meta: {
      title: '清运员申请',
      keepAlive: false
    }
  },
  {
    path: '/register',
    name: '注册',
    component: () => import('../views/register.vue'),
    meta: {
      title: '注册',
      keepAlive: false
    }
  },
  {
    path: '/Applicationstatus',
    name: '申请状态',
    component: () => import('../views/Applicationstatus.vue'),
    meta: {
      title: '申请状态',
      keepAlive: false
    }
  },
  {
    path: '/Errorpage',
    name: '错误提示',
    component: () => import('../views/Errorpage.vue'),
    meta: {
      title: '错误提示',
      keepAlive: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// title动态显示
router.beforeEach((to, from, next) => {
  document.title = to.matched[0].meta.title
  next()
})

router.afterEach(async (to) => {
  let baseLocation = "https://gy-wx.nbhhl.com"//自行改为项目地址
  if (window.__wxjs_is_wkwebview) { // 通过此属性判断IOS
    if (window.entryUrl == '' || window.entryUrl == undefined) {
      var url = baseLocation + to.fullPath
      window.entryUrl = url
    }
  } else { // 安卓
    window.entryUrl = baseLocation +
      to.fullPath
  }
  sessionStorage.setItem("url", window.entryUrl) //直接在使用页面 用来请求接口配置config
})
export default router
