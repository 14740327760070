import Vue from 'vue'
import 'vant/lib/index.css'
import {
  Swipe,
  SwipeItem,
  Icon,
  Button,
  Field,
  Picker,
  Popup,
  Sidebar,
  SidebarItem,
  TreeSelect,
  Radio,
  RadioGroup,
  Form,
  Switch,
  Tabs,
  Tab,
  Uploader,
  PullRefresh,
  List,
  Image,
  Checkbox,
  Cascader,
  DatetimePicker
} from "vant"
Vue.use(Cascader)
Vue.use(Checkbox)
Vue.use(Image)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Uploader)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Switch)
Vue.use(Form)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(TreeSelect)
Vue.use(SidebarItem)
Vue.use(Sidebar)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Field)
Vue.use(Button)
Vue.use(Icon)
Vue.use(SwipeItem)
Vue.use(Swipe)
Vue.use(DatetimePicker)