import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './vant/vant.js'

// 全局格式化时间
import {conversionTime} from '@/api/conversionTime.js'
Vue.prototype.conversionTime = conversionTime

// axios引入
import axios from 'axios'
Vue.prototype.$axios = axios
// 300毫秒延迟导入
import FastClick from 'fastclick'
FastClick.attach(document.body)

FastClick.prototype.focus = function (targetElement) { // 解决ios多次点击问题
  let length
  if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
    length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length)
  } else {
    targetElement.focus()
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
